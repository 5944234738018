import React, {useEffect, useState} from "react";
import {Connector} from "wagmi";
import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";


export default function ConnectionButton(
    {connector, onClick, disabled = false}: { connector: Connector, onClick: (connector: Connector) => void, disabled?: boolean }) {

    const [ready, setReady] = useState(false);

    useEffect(() => {
        (async () => {
            const provider = await connector.getProvider();
            setReady(true);
        })()
    }, [connector]);

    return (
        <Button theme={ButtonTheme.Secondary} scale={ButtonScale.Large} onClick={() => onClick(connector)} processing={!ready || disabled}>
            {connector.icon && <img src={connector.icon} alt={connector.name} className="h-6 mr-1"/>} Connect with {connector.name}
        </Button>
    );
}
