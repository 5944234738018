import {Connector, useAccount, useConnect, useDisconnect, useSignMessage, useSwitchChain, useWalletClient} from "wagmi";
import {Chain} from "viem";

export function useWallet({chain}: { chain: Chain }) {

    const {isConnected, chainId: connectedChainId} = useAccount();
    const {connectAsync, connectors} = useConnect();
    const {disconnectAsync} = useDisconnect();
    const {signMessageAsync} = useSignMessage();

    const client = useWalletClient();
    const {switchChainAsync} = useSwitchChain();

    async function connect(connector: Connector) {

        // Disconnect if already connected (disconnects from all connectors + accounts)
        if (isConnected) {
            await disconnectAsync();
        }

        await forceSwitchChainAsync(chain);

        const {accounts, chainId} = await connectAsync({
            connector: connector,
            chainId: chain.id
        });

        return {
            account: accounts[0], // Simplification: we select the first connected account by default
            chainId
        };
    }

    async function installChain(chain: Chain) {
        await client.data?.addChain({
            chain: chain
        });
    }

    async function forceSwitchChainAsync(chain: Chain) {
        try {
            if (chain.id !== connectedChainId) {
                await switchChainAsync({
                    chainId: chain.id
                });
            }

        } catch (e) {
            if (e instanceof Error) {
                switch (e.name) {
                    case 'ChainNotConfiguredForConnectorError': {
                        await installChain(chain);
                        break;
                    }
                }
            }

            throw e;
        }
    }

    return {
        connectors,
        connect,
        forceSwitchChainAsync,
        async signMessage(connector: Connector, message: string) {
            return await signMessageAsync({message});
        }
    }

}
