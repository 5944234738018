import Button, {ButtonScale, ButtonTheme} from "@/Components/Button";
import Modal from "@/Components/Modal";
import React, {useMemo, useReducer} from "react";
import {Connector} from "wagmi";
import {useWallet} from "@/Hooks/useWallet";
import {Chain, UserRejectedRequestError} from "viem";
import {IconLoader2} from "@tabler/icons-react";
import telemetry from "@/telemetry";
import {ReportableError} from "@/types/telemetry";

enum Status {
    IDLE, CONNECTING, SIGNING, ABORTED, ERRORED
}

interface State {
    status: Status,
    connector?: Connector,
    error?: string
}

enum WalletActionType {
    REQUESTED_SIGNATURE = 'w_request_signature', ABORTED = 'w_aborted', FAILED = 'w_failed', COMPLETED = 'w_completed'
}

enum UserActionType {
    START = 'u_start', CLOSE = 'u_close'
}

type Action = {
    action: UserActionType.START,
    connector: Connector
} | {
    action: WalletActionType.FAILED | WalletActionType.ABORTED,
    error: string
} | {
    action: WalletActionType.REQUESTED_SIGNATURE | WalletActionType.COMPLETED | UserActionType.CLOSE,
}

interface Controller {
    state: State,
    interacting: boolean,
    dispatch: (action: Action) => void,
    begin: (connector: Connector) => void,
    chain: Chain
}

function isWalletAction(action: Action) {
    return action.action === WalletActionType.REQUESTED_SIGNATURE
        || action.action === WalletActionType.ABORTED
        || action.action === WalletActionType.FAILED
}

function reducer(state: State, action: Action) {

    // Ignore incoming wallet actions on terminal states
    if ([Status.ABORTED, Status.ERRORED].includes(state.status) && isWalletAction(action)) {
        return state;
    }

    switch (action.action) {
        case UserActionType.START: {
            return {
                ...state,
                status: Status.CONNECTING,
                connector: action.connector
            }
        }
        case WalletActionType.REQUESTED_SIGNATURE: {
            return {
                ...state,
                status: Status.SIGNING
            }
        }
        case WalletActionType.FAILED:
        case WalletActionType.ABORTED: {
            return {
                ...state,
                status: action.action === WalletActionType.ABORTED ? Status.ABORTED : Status.ERRORED,
                error: action.error
            }
        }
        case WalletActionType.COMPLETED:
        case UserActionType.CLOSE: {
            return {
                status: Status.IDLE,
            };
        }
        default: {
            return state;
        }
    }
}

export function useWalletConnector({chain, fetchChallenge, onSignature}: {
    chain: Chain,
    fetchChallenge: (account: string, chainId: number) => Promise<string>,
    onSignature: (account: string, chainId: number, challenge: string, signature: string) => Promise<void>
}) {

    const [state, dispatch] = useReducer(reducer, {status: Status.IDLE});
    const interacting = useMemo(() => state.status !== Status.IDLE, [state.status]);

    const {connectors, connect, signMessage} = useWallet({
        chain: chain
    });

    const operations = {

        begin: async (connector: Connector) => {

            dispatch({action: UserActionType.START, connector: connector});

            try {
                const {account, chainId} = await connect(connector);

                if (chainId !== chain.id) {
                    dispatch({
                        action: WalletActionType.FAILED,
                        error: `Make sure that your wallet is connected to ${chain.name} and try again.`
                    });
                    return;
                }

                await operations.requestChallenge(connector, account, chainId);

            } catch (e) {

                if (e instanceof UserRejectedRequestError) {
                    dispatch({action: WalletActionType.ABORTED, error: "You rejected the request in your wallet."})
                    return;
                }

                telemetry.report(e as ReportableError);

                dispatch({
                    action: WalletActionType.FAILED,
                    error: "An unknown error occurred while attempting to connect with your wallet. Please try again, or try using a different wallet."
                });
            }

        },

        requestChallenge: async (connector: Connector, account: string, chainId: number) => {

            try {
                const challenge = await fetchChallenge(account, chainId);
                await operations.signChallenge(connector, account, chainId, challenge);

            } catch (e) {

                telemetry.report(e as ReportableError);

                dispatch({
                    action: WalletActionType.FAILED,
                    error: (typeof e === 'string') ? e : "An unknown error occurred while processing your wallet verification request. Please try again later."
                });
            }

        },

        signChallenge: async (connector: Connector, account: string, chainId: number, challenge: string) => {

            dispatch({action: WalletActionType.REQUESTED_SIGNATURE});

            try {
                const signature = await signMessage(connector, challenge);
                await operations.submitChallenge(connector, account, chainId, challenge, signature);

            } catch (e) {

                if (e instanceof UserRejectedRequestError) {
                    dispatch({action: WalletActionType.ABORTED, error: "You rejected the request in your wallet."})
                    return;
                }

                telemetry.report(e as ReportableError);

                dispatch({
                    action: WalletActionType.FAILED,
                    error: "An unknown error occurred while interacting with your wallet."
                });
            }

        },

        submitChallenge: async (connector: Connector, account: string, chainId: number, challenge: string, signature: string) => {

            try {
                await onSignature(account, chainId, challenge, signature);

            } catch (e) {

                telemetry.report(e as ReportableError);

                dispatch({
                    action: WalletActionType.FAILED,
                    error: (typeof e === 'string') ? e : "An unknown error occurred while processing your wallet signature."
                });

            }

        }

    }

    return {
        connectors,
        controller: {
            state: state,
            dispatch: dispatch,
            begin: operations.begin,
            interacting: interacting,
            chain: chain
        } as Controller
    }

}

export default function WalletConnector({controller}: { controller: Controller }) {

    const state = controller.state;

    const processing = useMemo(() => state.status === Status.CONNECTING || state.status === Status.SIGNING, [state.status]);
    const failed = useMemo(() => state.status === Status.ERRORED || state.status === Status.ABORTED, [state.status]);

    return (
        <Modal open={controller.interacting} disableClose={true}>

            <div className="flex flex-col gap-4 text-center py-1">

                <div>

                    <h4 className="text-lg font-semibold mb-1">
                        {processing && <div className="flex items-center justify-center gap-2">
                            <IconLoader2 className="animate-spin"/> Loading ...
                        </div>}
                        {state.status === Status.ABORTED && <>Aborted</>}
                        {state.status === Status.ERRORED && <>An error occurred</>}
                    </h4>

                    <p className="text-stone-700">
                        {state.status === Status.CONNECTING && <>Establishing wallet connection</>}
                        {state.status === Status.SIGNING && <>Requesting user verification signature</>}
                        {failed && <>{state.error}</>}
                    </p>

                </div>

                {failed && <div className="flex flex-col gap-1 mt-4">
                    <Button onClick={() => controller.begin(state?.connector!)} scale={ButtonScale.Large}
                            theme={ButtonTheme.Secondary}>
                        Try again
                    </Button>

                    <Button onClick={() => controller.dispatch({action: UserActionType.CLOSE})}
                            scale={ButtonScale.Large}
                            theme={ButtonTheme.Transparent}>
                        Close
                    </Button>
                </div>}

            </div>

        </Modal>
    );

}
